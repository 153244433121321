import { Column, Icon, Row, Tag } from '../../../../components/global/utils';
import styled, { css } from 'styled-components';

import { respondTo } from '../../../../utils/breakpoints';

export const ClockIcon = styled(Icon)`
  margin: 2px 8px 0 0;
`;
export const RoundedBox = styled.div`
  border: 1px solid #4ebfee;
`;

export const coverCss = css`
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    i {
      margin: 0;
    }
  }

  span {
    flex-grow: 1;
  }
`;

export const Bar = styled(Row)`
  height: 5rem;
  max-width: 1180px;
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  border-radius: 10px;
  margin-bottom: 1rem;
  background: white;
  color: #0f5575;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 29px;
  justify-content: space-between;

  &.event-page {
    min-height: 5rem;
    height: auto !important;
    max-height: auto;
  }

  ${coverCss}
`;

export const Hour = styled(Row)`
  font-family: 'Montserrat';
  color: #4ebfee;
  background: #f5fafd;
  height: inherit;
  height: 100%;
  width: 90px;
  min-width: 80px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;
  padding-left: 1rem;
  margin-right: 1rem;
`;

export const Person = styled(Row)`
  color: #5fa5c5;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  img {
    margin-right: 1rem;
    object-fit: cover;
  }
`;

export const StyledTag = styled(Tag)`
  margin-right: 1rem;
  margin-left: 1.5rem;
  @media only screen and (max-width: 768px) {
    margin: 0;
    margin-left: 0.5rem;
  }
`;

export const AgendaTitle = styled.span`
  max-width: 695px;
  font-size: 16px;
  margin: 12px 0;
  &.event {
    margin: 0px;
    color: var(--ui-dark-blue, #0F5575);
    /* Body/M Regular */
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    padding: 0px;
  }
  &.speaker {
    margin: 0px 0px;
    color: var(--ui-blue, #5FA5C5);
    /* Body/S Regular */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    width: 100%;
    max-width: 220px !important;
    ${respondTo.m`
      width: 100%;
      max-width: 500px !important;  
    `}
  }
`;

export const MobileColumn = styled(Column)`
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  border-radius: 10px;
  margin-bottom: 1.5rem;
  background: white;
`;

export const MobileRow = styled(Row)`
  min-height: 80px;
  color: #0f5575;
  font-family: 'Montserrat';
  font-size: 18px;
  ${coverCss}
`;

export const MobileAgendaTitle = styled.span`
  line-height: 150%;
  color: #0f5575;
`;

export const Label = styled.span`
  color: var(--ui-light-blue, #4EBFEE);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 4px 16px !important;
  align-items: flex-start;
  gap: 10px;
  max-width: 70px !important;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--ui-blue-tint, #F5FAFD);
  ${respondTo.m`
  max-width: 60px !important;
  `}
`
