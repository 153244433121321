import {
  AgendaTitle,
  Bar,
  Hour,
  Label,
  Person,
  StyledTag
} from './styled.components';
import {
  ClockIcon,
  MobileAgendaTitle,
  MobileColumn,
  MobileRow,
  RoundedBox
} from './styled.components';
import {
  Column,
  Icon,
  Row,
  isWindow
} from '../../../../components/global/utils';

import { Col } from '../../../../components/sections/styled.components';
import React from 'react';
import moment from 'moment/moment';

const parseTimeBox = (timeBox) => {
  let hours = timeBox / 60;
  let rhours = Math.floor(hours);
  let minutes = Math.round((hours - rhours) * 60);
  return `${rhours ? rhours + 'h' : ''} ${minutes ? minutes + ' m' : ''}`;
};

/*TODO: Cos tu nie dziala, przy ostatnim warsztacie niektorzy speakerzy sie nie wyswietlili,
niektore wartosci widzi jako undefined, mozliwy problem w contentfulu*/

export const AgendaBar = (props, isDay = false) => {
  const isItEvent = props.speakers ? true : false;
  const startDate = isDay
    ? moment(new Date(props.startTime)).format('DD.MM')
    : moment(new Date(props.startTime)).format('HH:mm');
  const speakersString = () => {
    if (typeof props.speakers === 'string') {
      return props.speakers;
    } else if (props?.speakers?.length > 1) {
      return props?.speakers.map((speaker) => speaker.name).join(', ');
    }
    return null;
  };
  return (
    isWindow() && (
      <Bar className={isItEvent ? 'event-page' : ''}>
        <Row className="align-center">
          <Hour style={{ height: '100%' }} className="align-center">
            {startDate}
          </Hour>
          {!props.speakers ? (
            <AgendaTitle>{props.name}</AgendaTitle>
          ) : (
            <Col className="align-start small-gap">
              <AgendaTitle className="event">{props.name}</AgendaTitle>
              <AgendaTitle className="speaker">{speakersString()}</AgendaTitle>
            </Col>
          )}
        </Row>
        {!isDay && (
          <Row className="align-center">
            {!props.isBreak ? (
              !isItEvent ? (
                <Person className="align-center">
                  <img
                    alt={props?.speaker?.name}
                    className="cover"
                    src={props?.speaker?.photo?.file.url}
                  />
                  {props?.speaker?.name}
                </Person>
              ) : (
                <Label>
                  {!props.activityType ? 'Panel' : props?.activityType}
                </Label>
              )
            ) : (
              props.isBreak && (
                <RoundedBox className="cover">
                  <Icon name={props.icon} />
                </RoundedBox>
              )
            )}
            <StyledTag className="align-center">
              <ClockIcon name="clock"></ClockIcon>{' '}
              {parseTimeBox(props.panelLength)}
            </StyledTag>
          </Row>
        )}
      </Bar>
    )
  );
};

export const MobileAgendaBar = (props, isDay = false) => {
  const isItEvent = props.speakers ? true : false;
  const startDate = isDay
    ? moment(new Date(props.startTime)).format('DD:MM')
    : moment(new Date(props.startTime)).format('HH:mm');
  const speakersString = () => {
    if (typeof props.speakers === 'string') {
      return props.speakers;
    } else if (props?.speakers?.length > 1) {
      return props?.speakers?.map((speaker) => speaker.name).join(', ');
    }
    return null;
  };
  return (
    isWindow() && (
      <MobileColumn>
        <MobileRow
          className="align-center"
          style={{ borderBottom: '1px solid #C4DFEC', paddingLeft: '1rem' }}>
          {props.isBreak && (
            <RoundedBox className="cover">
              <Icon name={props.icon} />
            </RoundedBox>
          )}
          {!props.isBreak && !isItEvent ? (
            <Person className="align-center">
              <img
                alt={props?.speaker?.name}
                className="cover"
                src={props?.speaker?.photo?.file.url}
              />
              {props?.speaker?.name}
            </Person>
          ) : (
            <AgendaTitle className="speaker">{speakersString()}</AgendaTitle>
          )}
          {props.speakers ? (
            <Column
              style={{
                alignItems: 'start',
                justifyContent: 'center',
                gap: '4px'
              }}>
              {isItEvent && (
                <Label style={{ width: '100%', textAlign: 'center' }}>
                  {props?.activityType ? props?.activityType : 'Panel'}
                </Label>
              )}
              <StyledTag style={{ marginLeft: '0px' }} className="align-center">
                <ClockIcon name="clock"></ClockIcon>{' '}
                {parseTimeBox(props.panelLength)}
              </StyledTag>
            </Column>
          ) : (
            <StyledTag className="align-center">
              <ClockIcon name="clock"></ClockIcon>{' '}
              {parseTimeBox(props.panelLength)}
            </StyledTag>
          )}
        </MobileRow>
        <MobileRow>
          <Hour className="align-center" style={{ marginRight: '0' }}>
            {startDate}
          </Hour>
          <MobileAgendaTitle>{props?.name}</MobileAgendaTitle>
        </MobileRow>
      </MobileColumn>
    )
  );
};
