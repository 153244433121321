import { AgendaBar, MobileAgendaBar } from './components/agenda';
import { Column, isWindow, navigateTo } from '../../components/global/utils';
import {
  Description,
  FullWidthTitle,
  Img,
  SignInButton,
  WhiteText
} from './styled.components';
import {
  FullWidthSection,
  Page,
  PageContent,
  PageSubTitle,
  PageTitle
} from '../../components/global/page';

import Breadcrumbs from '../../components/global/breadcrumbs';
import { Button } from '../../components/global/button';
import Layout from '../../components/layout/layout';
import React from 'react';
import { RecommendedElements } from '../components/recommended-elements';
import Seo from '../../components/seo';
import { Speaker } from './components/speakers';
import { SpeakerGrid } from './styled.components';
import { ThemeProvider } from 'styled-components';
import { WorkshopDetails } from '../../views/workshops/components/workshopBox';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { theme } from '../../themes/default';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function WorkshopPage({ pageContext }) {
  const { t } = useTranslation();
  const { workshop, edges } = pageContext;

  const findSimilarWorkshops = (currentWorkshop, allWorkshops) => {
    return allWorkshops.filter(
      (workshop) =>
        workshop.node.type === currentWorkshop.type &&
        workshop.node.slug !== currentWorkshop.slug
    );
  };

  const similarWorkshops = findSimilarWorkshops(workshop, edges).splice(0, 3);
  const latestWorkshops = edges.slice(0, 3);

  // Merge similar and latest workshops, if there are not enough similar workshops, latest are added, if there are no similar, only latest are shown, if there is enough similar, only similar are shown

  const recommendedWorkshops = [...similarWorkshops, ...latestWorkshops].splice(
    0,
    3
  );

  const breadcrumbsPath = [
    { name: t('workshop-page.breadcrumbs.workshops'), path: '/news/workshops' },
    { name: t('workshop-page.breadcrumbs.workshop'), path: '' }
  ];
  let Agenda = AgendaBar;
  const isMobile = isWindow() && window.innerWidth < 768;

  if (isMobile) Agenda = MobileAgendaBar;

  const workshopDate = new Date(workshop.data);
  workshopDate.setHours(workshopDate.getHours() + 1);
  const isWorkshopActive = new Date() < workshopDate;

  return (
    <Layout withHeader={false}>
      <Seo
        title={workshop.title}
        description={workshop.description.description}
        ogImage={workshop.thumbnail.file.url}
      />
      <ThemeProvider theme={theme}>
        <Page className="workshopPage">
          <PageContent>
            <Breadcrumbs path={breadcrumbsPath}></Breadcrumbs>
            <PageTitle>{workshop.title}</PageTitle>
            <WorkshopDetails {...workshop} />
            <Description>
              {workshop.content
                ? renderRichText(workshop.content)
                : workshop.description.description}
            </Description>
            {isWorkshopActive && (
              <SignInButton
                className="solid"
                onClick={() => navigateTo(workshop.link, '_blank')}>
                {workshop.button ? workshop.button : t('workshop-page.sign-in')}
              </SignInButton>
            )}
            <Img src={workshop.thumbnail?.file?.url}></Img>
            {workshop.agenda && (
              <>
                <PageSubTitle>
                  <span>Agenda</span> spotkania
                </PageSubTitle>
                <Column>
                  {workshop.agenda.map((agendaItem) => (
                    <Agenda
                      workshop={workshop}
                      key={agendaItem.panelName}
                      {...agendaItem}
                      {...{
                        speaker: workshop.speakers.find(
                          (speaker) => speaker.id === agendaItem?.speaker?.id
                        ),
                        isMobile
                      }}
                    />
                  ))}
                </Column>
              </>
            )}
            {workshop.speakers && (
              <>
                <PageSubTitle style={{ marginTop: '7.5rem' }}>
                  Kogo posłuchasz na <span>warsztatach</span>
                </PageSubTitle>
                <SpeakerGrid style={{ marginBottom: '6rem' }}>
                  {workshop.speakers.map((speaker) => (
                    <>
                      <Speaker key={speaker.name} {...speaker}></Speaker>
                    </>
                  ))}
                </SpeakerGrid>
              </>
            )}
          </PageContent>
          {isWorkshopActive && (
            <FullWidthSection className="align-center">
              <FullWidthTitle>
                {t('workshop-page.full-width.title')}
              </FullWidthTitle>
              <WhiteText>{t('workshop-page.full-width.desc')}</WhiteText>
              <Button
                className="outlined"
                onClick={() => navigateTo(workshop.link, '_blank')}>
                {workshop.button
                  ? workshop.button
                  : t('workshop-page.full-width.button')}
              </Button>
            </FullWidthSection>
          )}
          <RecommendedElements
            elements={recommendedWorkshops}
            type="workshops"
          />
        </Page>
      </ThemeProvider>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
